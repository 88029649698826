import { Grid, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import {
  businessSelector,
  updateBusinessDetail,
} from "../../../services/business/business.slice.services";
import { profileSelector } from "../../../services/profile/profile.slice.services";
import BusinessDetailLoader from "../loader/business-detail-loader.component";
import FormFieldLabel from "./form-field-label.component";

const validationSchema = Yup.object().shape({
  status: Yup.string().required().label("Status"),
  name: Yup.string().required().label("Name"),
  slug: Yup.string().required().label("Slug"),
  tags: Yup.array().of(Yup.string()).required().label("Tags"),
  description: Yup.string().required().label("About Us"),
  location: Yup.string().required().label("Address"),
  locationLat: Yup.string().required().label("Address"),
  locationLong: Yup.string().required().label("Address"),
  city: Yup.string().label("Address"),
  state: Yup.string().label("Address"),
  postcode: Yup.string().label("Address"),
  country: Yup.string().label("Address"),
  contactNo: Yup.string()
    .required()
    .label("Phone number")
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{8}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      "Phone number is not valid.",
    ),
  amenitiesId: Yup.array().of(Yup.number()).label("Amenities"),
  images: Yup.array().of(Yup.object().shape({})).min(1).label("Images"),
  email: Yup.string().nullable().email().label("Email"),
});

export default function EditBusinessDetailForm() {
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const theme = useTheme();
  const businessId = new URLSearchParams(location.search).get("businessId");
  const { updateBusinessDetailObj, getBusinessDetailObj, getBusinessAmenitiesObj } =
    useSelector(businessSelector);
  const { getProfileDetailObj } = useSelector(profileSelector);

  const onUpdateBusiness = (values) => {
    values.blockHourlyBooking = values.blockHourlyBooking === "true";
    setIsLoading(true);
    dispatch(updateBusinessDetail(values)).then(({ meta, payload, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const renderForm = () => {
    if (getBusinessDetailObj.status === "succeeded") {
      return (
        <Form
          validationSchema={validationSchema}
          onSubmit={onUpdateBusiness}
          initialValues={{
            type: "details",
            businessId,
            name: getBusinessDetailObj.data.name,
            slug: getBusinessDetailObj.data.slug,
            tags: getBusinessDetailObj.data.tags,
            description: getBusinessDetailObj.data.description,
            location: getBusinessDetailObj.data.location,
            locationLat: getBusinessDetailObj.data.locationLat,
            locationLong: getBusinessDetailObj.data.locationLong,
            city: getBusinessDetailObj.data.city,
            state: getBusinessDetailObj.data.state,
            postcode: getBusinessDetailObj.data.postcode,
            contactNo: getBusinessDetailObj.data.contactNo,
            status: getBusinessDetailObj.data.status,
            amenitiesId: getBusinessDetailObj.data.amenities.map((option) => option.id),
            blockHourlyBooking: getBusinessDetailObj.data.blockHourlyBooking ? "true" : "false",
            email: getBusinessDetailObj.data.email,
            exclusiveReferrals: getBusinessDetailObj.data.exclusiveReferrals,
            requireMembership: getBusinessDetailObj.data.requireMembership,
          }}
        >
          <Grid item xs={12}>
            <Text variant="screenLabel">Business Details</Text>
          </Grid>
          <Grid item xs={12}>
            <FormFieldLabel name="name" placeholder="Enter business name" label="Business Name" />
          </Grid>
          <Grid item xs={12}>
            <FormFieldLabel name="slug" placeholder="Enter Slug" label="Slug" />
          </Grid>
          <Grid item xs={12}>
            <FormFieldLabel
              name="tags"
              label="Tags"
              placeholder="Tags"
              itemList={[
                { label: "Fitness", value: "fitness" },
                { label: "Health", value: "health" },
                { label: "Wellness", value: "wellness" },
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <FormFieldLabel
              name="contactNo"
              placeholder="Enter business contact number"
              label="Contact Number"
            />
          </Grid>
          <Grid item xs={12}>
            <FormFieldLabel
              name="email"
              placeholder="Enter business email (Optional)"
              label="Email"
            />
          </Grid>
          <Grid item xs={12}>
            <FormFieldLabel
              name="description"
              placeholder="Brief description about your business"
              label="About Us"
            />
          </Grid>
          <Grid item xs={12}>
            <FormFieldLabel name="location" placeholder="Enter business address" label="Address" />
          </Grid>
          <Grid item xs={12}>
            <FormFieldLabel
              name="status"
              label="Status"
              placeholder=""
              disabled={getProfileDetailObj.data && getProfileDetailObj.data.status !== "approved"}
            />
            {getProfileDetailObj.data && getProfileDetailObj.data.status !== "approved" && (
              <>
                <Spacer />
                <Text style={{ color: theme.palette.colors.text.rejected }}>
                  Only approved merchant accounts can update the status of the business.
                </Text>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormFieldLabel
              disabled
              name="exclusiveReferrals"
              label="Exclusive Referrals"
              placeholder="Exclusive Referrals"
            />
          </Grid>
          <Grid item xs={12}>
            <FormFieldLabel
              disabled
              name="requireMembership"
              label="Require Membership"
              placeholder="Require Membership"
            />
          </Grid>
          <Grid item xs={12}>
            <FormFieldLabel
              name="blockHourlyBooking"
              label="Hourly Booking"
              placeholder="Hourly Booking"
              itemList={[
                { label: "Blocked", id: 1, value: "true" },
                { label: "Not blocked", id: 2, value: "false" },
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <FormFieldLabel
              label="Amenity"
              name="amenitiesId"
              placeholder="Select amenities"
              itemList={getBusinessAmenitiesObj.data.map((item) => ({
                value: item.id,
                label: item.amenity.replace("_", " "),
              }))}
            />
          </Grid>
          <Grid item xs={12} sx={{ justifyContent: "flex-end", display: "flex" }}>
            <FormSubmitButton
              width="125px"
              isLoading={updateBusinessDetailObj.status === "pending"}
            >
              <Text type="WhiteColor">Update</Text>
            </FormSubmitButton>
          </Grid>
        </Form>
      );
    }
    return <BusinessDetailLoader />;
  };

  return (
    <Grid container spacing={2}>
      <BackdropLoading isLoading={isLoading} />
      {renderForm()}
    </Grid>
  );
}
